
























































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState } from 'vuex'
import { ApiResponse, Pagination, Document } from '@/interfaces'

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      busy: false,
      sales: [], // as Document[],
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      pageSize: 1,
      orderBy: null as null | string,
      order: null as null | string,
      structureId: null as null | string,
      structures: [] as Record<string, string | null>[],
      stats: '',
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure) {
      this.structureId = this.$route.query.structure as string
    }

    this.getSales()
  },
  methods: {
    updateRoute(resetPage = false) {
      if (resetPage === true) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureId,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getSales() {
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#salesBookTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/document/sales/list', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              structure: this.structureId,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.sales = apiResponse.data.documents
            this.stats = apiResponse.data.stats

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
            this.pageSize = pagination.numItemsPerPage
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getStructureName(document: Document) {
      return document.structure?.name ?? '-'
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    openDocument(document: Document) {
      let url = ''

      if (document.format == 'pdf') {
        url = `${process.env.VUE_APP_API_URL}/document/${document.accessToken}/download?contentd=inline`
      } else {
        url = `${process.env.VUE_APP_API_URL}/document/${document.accessToken}/download`
      }

      window.open(url, '_blank')
    },
    filterStructure(id: string) {
      this.structureId = id
      this.updateRoute(true)
    },
  },
})
